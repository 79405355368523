import PenIcon from '@/material-icons/400-24px/pen_icon.svg?react';
import PodcastIcon from '@/material-icons/400-24px/podcast.svg?react';
import ChatIcon from '@/material-icons/400-24px/chat.svg?react';
import WebsiteIcon from '@/material-icons/400-24px/website_icon.svg?react';
import RssFeedIcon from '@/material-icons/400-24px/rss_feed.svg?react';
import ButterflyIcon from '@/material-icons/400-24px/butterfly.svg?react';
import ThreadIcon from '@/material-icons/400-24px/thread.svg?react';
import Mastodon from '@/material-icons/400-24px/mastodon.svg?react';

export const icons = {
    "pen":PenIcon,
    "podcast":PodcastIcon,
    "chat":ChatIcon,
    "website":WebsiteIcon,
    "rss-feed":RssFeedIcon,
    "bluesky":ButterflyIcon,
    "thread":ThreadIcon,
    "mastodon":Mastodon,
}